




































































































































































































































































































































































































































import dayjs from 'dayjs'
import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/ProgramaticPointController'
import { Utils, EventBusConstants } from '@/app/infrastructures/misc'
import Badge from '@/app/ui/components/Badge/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import { PARCEL_POINT_NAME } from '@/app/infrastructures/misc/Constants/programaticPoint'
import useProgrammaticPoint from '@/app/ui/views/Saldo/ProgramaticPoint/useCase'

@Component({
  computed: {
    Utils() {
      return Utils
    },
  },
  components: {
    Badge,
    Button,
    Modal,
    EditIcon,
    LoadingOverlay,
  },
})
export default class ProgramDetail extends Vue {
  useActionProgrammaticPoint = useProgrammaticPoint()
  isGuest = Utils.isGuest()
  controller = controller
  confirmationModal = false
  successModal = false

  created(): void {
    controller.getTriggers()
    controller.getProgramDetail(this.$route.params.id)
  }

  get getUsertype(): string {
    let result = controller.programDetail.target

    if (
      result &&
      controller.programDetail.membershipLevel
    ) {
      result += ': ' + controller.programDetail.membershipLevel.map(ml => ml.label.toUpperCase()).join(', ')
    }

    return result || ''
  }

  get isOnScheduled(): boolean {
    return controller.programDetail.triggerBy === 'ON_SCHEDULE'
  }

  get statusBadgeType(): string {
    if (this.controller.programDetail?.status?.toLowerCase() === 'expired') {
      return 'expire'
    } else if (
      this.controller.programDetail?.status?.toLowerCase() === 'cancelled'
    ) {
      return 'cancelled'
    } else if (
      this.controller.programDetail?.status?.toLowerCase() === 'active' ||
      this.controller.programDetail?.status?.toLowerCase() === 'published'
    ) {
      return 'success'
    } else {
      return 'warning'
    }
  }

  get saldoPoint(): string {
    if (this.controller.programDetail?.percentOfTransaction) {
      return `${this.controller.programDetail?.percentOfTransaction}%`
    }

    return `Rp${Utils.currencyDigit(
      this.controller.programDetail?.points || 0
    )}`
  }

  get pointExpiration(): string {
    if (this.controller.programDetail?.expiredDate) {
      return dayjs(this.controller.programDetail?.expiredDate).format(
        'DD MMMM YYYY'
      )
    }

    return `${this.controller.programDetail.expiryDay} days`
  }

  get pointLimitPerDay(): number | string {
    return this.controller.programDetail?.limitPerDay || '-'
  }

  get pointLimitPerUser(): number | string {
    return this.controller.programDetail?.limitPerUser || '-'
  }

  get paymentMethodUsed(): string {
    return this.controller.programDetail?.paymentMethodUsed || '-'
  }

  get invoiceSource(): string {
    return this.controller.programDetail?.invoiceSource || '-'
  }

  get minimumTransaction(): string {
    if (!this.controller.programDetail.minimumTransaction) {
      return '-'
    }

    return `Rp${Utils.currencyDigit(
      this.controller.programDetail?.minimumTransaction
    )}`
  }

  get maxAmountGiven(): string {
    if (!this.controller.programDetail.maxAmountGiven) {
      return '-'
    }

    return `Rp${Utils.currencyDigit(
      this.controller.programDetail?.maxAmountGiven
    )}`
  }

  get listOfCities(): string | undefined {
    return this.controller.programDetail?.routes?.map(r => r.city).join(', ')
  }

  get budgetSource(): string {
    const budgetSource = this.controller.programDetail?.budgetSource
    if (!budgetSource) {
      return '-'
    }

    if (budgetSource.startsWith('OTHERS')) {
      return budgetSource.replace('OTHERS', 'OTHERS - ')
    }

    return budgetSource
  }

  get budgetAmount(): string {
    if (this.controller.programDetail?.budgetAmount) {
      return `Rp${Utils.currencyDigit(
        this.controller.programDetail?.budgetAmount
      )}`
    }
    return '-'
  }

  get isParcelPoint(): boolean {
    return this.useActionProgrammaticPoint.isParcelPointCondition(
      controller.programDetail?.purpose,
      controller.programDetail?.triggerBy,
      controller.programDetail?.target
    )
  }

  get titleLabelPoint(): string {
    if (this.isParcelPoint) {
      return PARCEL_POINT_NAME
    }
    return 'Saldo Point'
  }

  get titleLabelLimitPerDay(): string {
    if (this.isParcelPoint) {
      return 'Daily Quota'
    }
    return 'Point Limit in a day'
  }

  private cancelProgram(): void {
    controller.cancelProgram(this.$route.params.id)
    this.confirmationModal = false
  }

  private onCloseSuccessModal(): void {
    controller.getProgramDetail(this.$route.params.id)
    this.successModal = false
  }

  private makeCurrencyRp(param: number): string {
    if (!param) {
      return '-'
    }
    return `Rp${Utils.currencyDigit(param)}`
  }

  get maxDeviceIdPerCustomer(): string {
    if (!this.controller.programDetail.deviceIdLimitCustomer || this.controller.programDetail.deviceIdLimitCustomer === 0) {
      return '-'
    }

    return `${this.controller.programDetail.deviceIdLimitCustomer}`
  }

  @Watch('controller.statusCancelledProgram')
  onStatusCancelledProgramChanged(status: string): void {
    if (status !== '' && status === EventBusConstants.CANCEL_PROGRAM_SUCCESS) {
      this.successModal = true
    }
  }

  beforeDestroy(): void {
    controller.setStatusCancelledProgram('')
  }
}
